/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React from "react"
import styled from "styled-components/macro"
import { GatsbyImage } from "gatsby-plugin-image"

import { QUERY, COLORS } from "../../constants"

const AdminCard = ({ titolo, nome, file }) => {
  return (
    <Wrapper>
      {file && <Image image={file.childImageSharp.gatsbyImageData} alt="" />}
      <LinkBox>
        <Titolo>{titolo}</Titolo>
        <Nome>{nome}</Nome>
      </LinkBox>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 350px;
  min-width: 300px;
  min-height: 150px;
  width: 100%;
  padding: 2rem;
  border-radius: 30px;
  border: 1px solid ${COLORS.blue};
  background: ${COLORS.white};

  @media ${QUERY.small} {
    min-width: 250px;
    min-height: 100px;
    padding: 1.5rem;
    margin-top: 3rem;
  }

  @media ${QUERY.smaller} {
    padding: 1rem;
  }
`

const Titolo = styled.span`
  color: ${COLORS.black};
  background: ${COLORS.blue};
  text-decoration: none;
  font-size: calc(1.2 * var(--baseline-size));
  font-weight: 100;
  margin-top: 1rem;
  border-radius: 10px;
  padding: 0.5rem;

  @media ${QUERY.medium} {
    font-size: calc(1.1 * var(--baseline-size));
  }

  @media ${QUERY.smaller} {
    font-size: calc(1 * var(--baseline-size));
  }
`

const Nome = styled.span`
  color: ${COLORS.black};
  text-transform: capitalize;
  font-size: calc(1.2 * var(--baseline-size));
  font-weight: 100;
  margin-top: 1rem;
  border-radius: 10px;
  padding: 0.5rem;

  @media ${QUERY.medium} {
    font-size: calc(1.1 * var(--baseline-size));
  }

  @media ${QUERY.smaller} {
    font-size: calc(1 * var(--baseline-size));
  }
`

const Image = styled(GatsbyImage)`
  object-fit: cover;
  width: 100%;
  max-width: 330px;
  min-width: 280px;
  min-height: 380px;
  max-height: 380px;
  border-radius: 20px;
  img {
    border-radius: 20px;
  }

  @media ${QUERY.smaller} {
    min-height: 300px;
    max-height: 300px;
  }
`

const LinkBox = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
`

export default AdminCard
