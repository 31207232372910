/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React from "react"
import styled from "styled-components/macro"

import { QUERY, COLORS } from "../../constants"

const DispensaCard = ({ titolo, url }) => {
  return (
    <Wrapper>
      <Titolo>{titolo}</Titolo>
      <LinkBox>
        <Linky href={url} target="_blank" rel="noopener noreferrer">
          Apri il file nel browser
        </Linky>
        <Linky href={url} download>
          Scarica il file
        </Linky>
      </LinkBox>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  min-height: 150px;
  width: 100%;
  padding: 2rem 3rem;
  border-radius: 30px;
  border: 1px solid ${COLORS.blue};
  background: ${COLORS.white};
  @media ${QUERY.small} {
    min-width: 250px;
    min-height: 100px;
    padding: 2rem;
  }
  @media ${QUERY.smallest} {
    padding: 1.5rem;
  }
`

const Titolo = styled.span`
  text-transform: uppercase;
  font-size: calc(1.2 * var(--baseline-size));
  font-weight: 700;
  margin-bottom: 1.5rem;

  @media ${QUERY.medium} {
    font-size: calc(1.1 * var(--baseline-size));
  }

  @media ${QUERY.smaller} {
    font-size: calc(1 * var(--baseline-size));
  }
`

const LinkBox = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
`

const Linky = styled.a`
  color: ${COLORS.black};
  background: ${COLORS.blue};
  text-decoration: none;
  font-size: calc(1.2 * var(--baseline-size));
  font-weight: 100;
  margin-top: 1rem;
  border-radius: 10px;
  padding: 0.5rem;

  @media ${QUERY.medium} {
    font-size: calc(1.1 * var(--baseline-size));
  }

  @media ${QUERY.smaller} {
    font-size: calc(1 * var(--baseline-size));
  }
`

export default DispensaCard
