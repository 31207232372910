import * as React from "react"
import { Helmet } from "react-helmet"

import HeaderSpazio from "../../components/HeaderSpazio/HeaderSpazio"
import ModulisticaSoci from "../../components/ModulisticaSoci"
import Consiglio from "../../components/Consiglio"
import FooterSpazio from "../../components/FooterSpazio"
import Navigator from "../../components/Navigator"

import FontStyles from "../../components/FontStyles"
import GlobalStyles from "../../components/GlobalStyles"

const Home = () => (
  <>
    <FontStyles />
    <GlobalStyles />
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />
    </Helmet>
    <HeaderSpazio />
    <Navigator />
    <ModulisticaSoci />
    <Consiglio />
    <FooterSpazio />
  </>
)

export default Home
