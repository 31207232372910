/* eslint-disable react/prop-types */
import React from "react"
import styled from "styled-components/macro"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"

import SwiperCore, { Pagination, Navigation, Mousewheel } from "swiper"
import { QUERY } from "../../constants"

SwiperCore.use([Pagination, Navigation, Mousewheel])

const LibriSwiper = ({ slides }) => {
  return (
    <Wrapper>
      <Swiper
        spaceBetween={20}
        pagination={{ clickable: true }}
        navigation={{ clickable: true }}
        className="mySwiper"
        grabCursor={true}
        freeMode={true}
        mousewheel={{
          releaseOnEdge: true,
          forceToAxis: true,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          720: {
            slidesPerView: 2,
          },
          1400: {
            slidesPerView: 3,
          },
          1700: {
            slidesPerView: 4,
          },
        }}
      >
        {slides.map((slideContent, index) => (
          <SwiperSlide key={slideContent} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  padding: 3rem 0;

  @media ${QUERY.medium} {
    padding: 2rem 0;
  }

  @media ${QUERY.small} {
    padding: 1rem 0;
  }
`
export default LibriSwiper
