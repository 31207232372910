import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components/macro"

import { QUERY, COLORS } from "../../constants"

const Navigator = () => (
  <Wrapper>
    <StyledLink to="/spazio-soci/home">Home</StyledLink>
    <StyledLink to="/spazio-soci/biblioteca">Biblioteca</StyledLink>
    <StyledLink to="/spazio-soci/ricette">Ricette</StyledLink>
  </Wrapper>
)

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding-left: 5rem;

  @media ${QUERY.small} {
    padding-left: 2.5rem;
  }

  @media ${QUERY.smaller} {
    padding-left: 1.5rem;
  }

  @media ${QUERY.smallest} {
    padding-left: 1rem;
  }
`

const StyledLink = styled(GatsbyLink)`
  color: ${COLORS.black};
  text-decoration: none;
  padding-bottom: 0.5rem;
  font-size: calc(1.6 * var(--baseline-size));
  font-weight: 100;
  margin-right: 1.5rem;

  @media ${QUERY.medium} {
    font-size: calc(1.4 * var(--baseline-size));
  }

  @media ${QUERY.small} {
    font-size: calc(1.4 * var(--baseline-size));
  }
`

export default Navigator
