/* eslint-disable react/no-children-prop */
import React from "react"
import styled from "styled-components/macro"
import { useStaticQuery, graphql } from "gatsby"

import AdminCard from "../AdminCard/AdminCard"
import LibriSwiper from "../LibriSwiper"

import { QUERY, COLORS } from "../../constants"

const Consiglio = () => {
  const data = useStaticQuery(graphql`
    query ConsiglioQuery {
      allStrapiConsiglioDirettivo {
        edges {
          node {
            foto {
              file {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            titolo
            nome
          }
        }
      }
    }
  `)

  const lista = data.allStrapiConsiglioDirettivo.edges
  const admins = lista.map(
    ({
      node: {
        id,
        titolo,
        nome,
        foto: { file },
      },
    }) => <AdminCard key={id} titolo={titolo} nome={nome} file={file} />
  )

  return (
    <>
      <MainTitle>Consiglio Direttivo</MainTitle>
      <LibriSwiper slides={admins} />
    </>
  )
}

const MainTitle = styled.h1`
  margin-top: 4rem;
  color: ${COLORS.red};
  padding: 2rem 4.5rem;
  margin-bottom: 2rem;
  font-size: calc(3.2 * var(--baseline-size));
  font-weight: 100;
  text-transform: uppercase;

  @media ${QUERY.medium} {
    font-size: calc(2.2 * var(--baseline-size));
  }

  @media ${QUERY.small} {
    font-size: calc(1.8 * var(--baseline-size));
    margin-bottom: 0;
  }

  @media ${QUERY.small} {
    padding: 2.5rem;
  }

  @media ${QUERY.smaller} {
    padding: 1.5rem;
  }

  @media ${QUERY.smallest} {
    padding: 1rem;
  }
`

export default Consiglio
